<!--
* @description:
* @fileName activeEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-drawer
    ref="drawerRef"
    v-model="dialogFormVisible"
    :title="title"
    :size="size"
    :before-close="handleClose"
    direction="rtl"
    custom-class="demo-drawer"
  >
    <div class="demo-drawer__content">
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item label="名称" prop="name" :label-width="80">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="时间" prop="activeTime" :label-width="80">
          <el-date-picker
            v-model="form.activeTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="是否显示" prop="isShow" :label-width="80">
          <el-switch v-model="form.isShow" />
        </el-form-item>
        <el-form-item label="数量" prop="num" :label-width="80">
          <el-input-number v-model="form.num" :min="1" :max="maxNum" />
        </el-form-item>

        <el-form-item label="描述" prop="explain" :label-width="80">
          <el-input
            v-model="form.explain"
            autocomplete="off"
            type="textarea"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="类型" :label-width="80">
          <el-radio-group v-model="form.stauts">
            <el-radio :label="'ON'">启用</el-radio>
            <el-radio :label="'OFF'">停用</el-radio>
            <el-radio :label="'DRAFT'">草稿</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="submitInfo()">提交</el-button>
    </div>
  </el-drawer>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { saveActiveTicket, editActiveTicket } from '@/api/active'

  import { getProvinceInfo, getCityInfo, getAreaInfo } from '@/api/shop'

  import { parseTime } from '@/utils/index'
  import VabQuill from '@/extra/VabQuill'
  import VabUpload from '@/extra/VabUpload'
  export default defineComponent({
    name: 'activeEdit',
    components: {
      VabQuill,
      VabUpload,
    },
    props: {
      maxNum: {
        type: Number,
        default: 10,
      },
      activeCode: {
        type: String,
        default: '',
      },
    },
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()
      const state = reactive({
        dialogVisible: false,
        dialogImageUrl: '',
        formRef: null,
        vabUploadRef: null,
        checkMenu: [],
        size: '50%',
        form: {
          type: 'free',
          isAudit: false,
          isShow: false,
        },
        rules: {
          name: [{ required: true, trigger: 'blur', message: '请输入名称' }],
          activeTime: [
            { required: true, trigger: 'blur', message: '请选择起止时间' },
          ],
          type: [
            { required: true, trigger: 'change', message: '请选择标签类型' },
          ],
          explain: [{ required: true, trigger: 'blur', message: '请填写说明' }],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
        pickerStartDate: null,
      })

      const showEdit = async (row) => {
        if (!row) {
          state.title = '添加票务'
          state.form = {
            num: 1,
          }
        } else {
          state.title = '编辑票务'
          row.activeTime = [
            parseTime(row.orderStartTime),
            parseTime(row.orderEndTime),
          ]
          state.form = Object.assign({}, row)
        }
        state.dialogFormVisible = true
      }

      const handleClose = () => {
        state['formRef'].resetFields()
        state.form = {}
        state.form.description = ''
        state.dialogFormVisible = false
      }

      onMounted(() => {})
      const submitInfo = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            state.form.orderStartTime = state.form.activeTime[0]
            state.form.orderEndTime = state.form.activeTime[1]
            state.form.activityCode = props.activeCode
            console.log(state.form)
            if (state.title == '编辑票务') {
              let data = await editActiveTicket(state.form)
            } else {
              let data = await saveActiveTicket(state.form)
            }
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            handleClose()
          }
        })
      }
      return {
        ...toRefs(state),
        showEdit,
        handleClose,
        submitInfo,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
